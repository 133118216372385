<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-1 dialogTitle-text">Assembly Info</div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleListAssemblyInfoModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
            <div class="text-center" >
              <hr
                style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;#41a7f5ad'
                  "
              />

              <div style="text-align: justify; font-size: 16px">
                <strong
                  >{{ this.assemblyData.assemble_date_str }},
                  {{ this.assemblyData.start_time_str }}-{{
                    this.assemblyData.end_time_str
                  }}
                  | {{ this.assemblyData.assemble_timezone }}</strong
                >&nbsp;&nbsp;&nbsp;
              <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="
                    toggleAssemblyModal({
                      show: true,
                      type: 'edit',
                      id: assemblyData.id,
                    })
                  "
                  v-if="this.adminAccess != 'Player'"
                >
                  mdi-pencil
                </v-icon>
                &nbsp;
                <v-icon
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="visitAllAssembly(assemblyData.id)"
                >
                  mdi-eye
                </v-icon>

                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />

                <br />

                <div
                  v-if="this.assemblyData.player_confirmations.length != 0"
                >
                  <strong>Players: </strong>
                  <div
                    v-for="(monthlySlot, index) in playeritems1"
                    :key="index"
                  >
                  
                      <tr class="students">
                        <td>{{ monthlySlot.player_name }}</td>
                        &nbsp;&nbsp;
                        <td
                          style="color: green; font-weight: 600"
                       v-if="monthlySlot.confirmation_status=='confirmed'" >
                     Confirmed
                        </td>
                          <td
                          style="color: red; font-weight: 600"
                       v-else >
                        Not Confirmed
                        </td>
                      </tr>
                  </div>
                </div>
                <div
                  v-if="
                    this.assemblyData.player_confirmations
                      .length == 0
                  "
                >
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in playeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                  <br />
                </div>
                <strong style="font-size: 20px; text-decoration: underline"
                  >Info:
                </strong>
                <br />
                <strong>Meeting Place: </strong>{{ this.assemblyData.school }}
                <br />
                <strong>Meeting Time: </strong
                >{{ this.assemblyData.meeting_time_str }}
                <br />
                <strong>Address: </strong>{{ this.assemblyData.address }}
                <br />
                <br />
                <strong>No.Of Assemblies: </strong
                >{{ this.assemblyData.total_assemblies }}
                <br />
                <strong>No. Of Student: </strong
                >{{ this.assemblyData.total_student }}

                <br />
                <br />
                <strong>Driver Name: </strong
                >{{ this.assemblyData.driver_name }}
                <br />
                <strong>Driver Cell: </strong>{{ this.assemblyData.phone }}

                <br />
                <br />
                <div>
                  <v-col cols="8">
                    <strong style="margin-left: -11px">Description: </strong>
                    <div
                        v-html="formattedSchoolGroups"
                      style="line-height: 1"
                    ></div>
                  </v-col>
                </div>
                <strong>Contact: </strong>{{ this.assemblyData.host_name }}
                <br />
                <strong>Phone No.: </strong>{{ this.assemblyData.host_phone }}
                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Game Info:
                </strong>
                <br />
                <br />
                <strong style="color: red">Ticket Sold Info: </strong
                >{{ this.assemblyData.total_ticket_sold }} /
                {{ this.assemblyData.all_seats }}
                <br />
                <strong>Game City and State: </strong
                >{{ this.assemblyData.assemble_city }},{{
                  this.assemblyData.assemble_state
                }}
                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Previous Event Info:</strong
                >
                <br />
                <strong>Year: </strong>2023
                <br />
                <strong>Ticket Sale: </strong>
                <br />
                <strong>Team: </strong
                >{{ this.assemblyData.assemble_team_name }}

                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >General Details:
                </strong>
                <br />
                <strong>Team Doing Game: </strong>
                {{ this.assemblyData.assemble_team_name }}
                <br />
                <strong>Host Group Name: </strong
                >{{ this.assemblyData.organization_name }}
                <br />
                <strong>Game Date And Time: </strong
                >{{ this.assemblyData.game_date }} -
                {{ converTime(this.assemblyData.game_starttime) }},
                <br />
                <strong>Site: </strong
                >{{ this.assemblyData.event_location_name }}
                <br />
                <strong> Event Address: </strong
                >{{ this.assemblyData.event_location }}
                <br />
                <strong>Seating Capacity: </strong>
                <br />
                <strong>Contract Type: </strong
                >{{ this.assemblyData.contract_type }}
                <br />
                <strong> Repeat game (Y/N?): </strong>
                <br />
                <strong> Wiz Kid? Yes </strong>
                <br />
                <br />
                <!-- <strong>Note: </strong>
                <ul>
                  <li v-for="(note, index) in assemblegameNotes" :key="index">
                  <div v-html="note"> </div>
                  </li>
                </ul>-->
                <div v-if="this.assemblyData.ticket_data != null">
                  <strong style="font-size: 20px; text-decoration: underline"
                    >Ticket Prices:
                  </strong>
                  <v-col cols="12">
                    <div class="td-container">
                      <td>
                        <label>Name</label>
                        <tr
                          v-for="(items, i) in ticketassemblyitems"
                          :key="i"
                          v-text="items.item_name"
                        ></tr>
                      </td>
                      <td>
                        <label>ADV</label>
                        <tr
                          v-for="(items, p) in ticketassemblyitems"
                          :key="p"
                          v-text="items.default_price"
                        ></tr>
                      </td>
                      <td>
                        <label>Door</label>
                        <tr
                          v-for="(items, p) in ticketassemblyitems"
                          :key="p"
                          v-text="items.door_price"
                        ></tr>
                      </td>
                    </div>
                  </v-col>
                </div>
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Contact Info:
                </strong>
                <br />
                <strong>Host Name: </strong
                >{{ this.assemblyData.host_name }} &nbsp;&nbsp;&nbsp;
                <br />
                <strong>Cell Phone: </strong>{{ this.assemblyData.host_phone }}
                <br />
                <strong>Email: </strong>{{ this.assemblyData.host_email }}
                <br />
                <strong>Sales Rep: </strong
                >{{ this.assemblyData.sales_rep_name }}
                <br />
                <strong>Event Support Wizard: </strong
                >{{ this.assemblyData.event_support_staff }}
                <br />
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
            </div>
        </v-card-text>
      </v-card>
    </v-dialog>
     <assembly-schedual-modal></assembly-schedual-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { ASSEMBLY_SCHEDUAL_API_GET } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { ROUTER_URL } from "../../../constants/urls";
export default {
  name: "ListCalendarManagement",
  components: {   AssemblySchedualModal: () =>
      import(
        "../../GameManagement/GameDashboardVASH/AssemblySchedualModal/AssemblySchedualModal"
      ),},
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      loading: false,
      formLoading: false,
      assemblyData: {},
        playeritems: [],
      playeritems1: [],
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "calendarManagement/getShowListAssemblyInfo",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleListAssemblyInfoModal({ show: value });
      },
    },
    type() {
      return this.$store.state.calendarManagement.listAssemblyInfoModal.type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Task Added";
      } else {
        return "Task Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New Task";
        case "edit":
          return "Edit Event";
        default:
          return "";
      }
    },
      formattedSchoolGroups() {
      return this.assemblyData.assemble_school_groups
         .map(
          (group, index) => `
            <br>
            <h4>School #${index + 1}</h4>
            <p><strong>Assembly or Cafeteria Visit:</strong> ${group.visit}</p>
            <p><strong >Name Of School : </strong>${group.school_name}</p>
            <p><strong>Time:</strong> ${group.start_time_str}-${group.end_time_str}</p>
            <p><strong>Address:</strong> ${group.school_address}</p>
             <p><strong>Phone #:</strong> ${group.principal_phone}</p>
             <p><strong>Principal Name:</strong> ${group.principal_name}</p>
            <p><strong>Email Address of Principal or Contact at School:</strong> ${group.principal_email}</p>
            <p><strong>Number of Students:</strong> ${group.number_of_students}</p>
            <p><strong>Grades:</strong> ${group.grades}</p>
              <p><strong>Location Within School:</strong> ${group.location_in_school}</p>
          
            <hr>
          `
        )
        .join("");
    },
  },
  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      toggleListAssemblyInfoModal:
        "calendarManagement/toggleListAssemblyInfoModal",
          toggleAssemblyModal: "gameManagement/toggleAssembly",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
      console.log("open modal");
      this.getAssemblyDetail();
    },
    closeCallBackModal() {},

    getAssemblyDetail() {
      const successHandler = (res) => {
        console.log(res);
        this.school = res.data.assemble_detail.school;
        this.address = res.data.assemble_detail.address;
        this.date = res.data.assemble_detail.date;
        this.start_time = res.data.assemble_detail.start_time_str;
        this.end_time = res.data.assemble_detail.end_time_str;
        this.noOfStudents = res.data.assemble_detail.total_student;
        this.noOfAssemblies = res.data.assemble_detail.total_assemblies;
        this.name = res.data.assemble_detail.driver_name;
        this.mobile = res.data.assemble_detail.phone;
        this.email = res.data.assemble_detail.email;
        this.notes = res.data.assemble_detail.note;
        this.loading = false;

        this.assemblyData = res.data.assemble_detail;
      if (this.assemblyData.ticket_data != null) {
          this.ticketassemblyitems = this.assemblyData.ticket_data;
        }
        console.log("this.ticketassemblyitems", this.ticketassemblyitems);
        if (this.assemblyData.player_list != null) {
          this.playeritems = this.assemblyData.player_list;
        }
         if (this.assemblyData.player_confirmations.length != 0) {
          this.playeritems1 = this.assemblyData.player_confirmations; 
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["assemble_id"] =
        this.$store.state.calendarManagement.listAssemblyInfoModal.assemblyID;
      Axios.request_GET(
        ASSEMBLY_SCHEDUAL_API_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    converTime(value) {
      if (value) {
        var temp = value.split(":");
        if (parseInt(temp[0]) > 12) {
          value = parseInt(temp[0]) - 12 + ":" + temp[1] + " PM";
        } else {
          value = temp[0] + ":" + temp[1] + " AM";
        }
      }

      return value;
    },
       visitAllAssembly(game) {
      const { id } = game;
      console.log(id);
      let routerData = this.$router.resolve({
        name: ROUTER_URL.gamePanel.children.gameallassembly.name,
        query: { game_id: game },
      });
      window.open(routerData.href, "_blank");
    },
  },
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
</style>
